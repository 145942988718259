<template>
  <div class="redpack">
    <div class="goodsItem" v-for="(item,index) in redPackList" :key="index" @click="toGoodsPage(index)">
      <img :src="item.goodsMainimagepath">
        <div class="textContainer">
          <div class="textTitle">{{item.goodsName}}</div>
          <div class="textPrice">
            <div class="relPrice">现价:{{item.goodsRelPrice}}</div>
            <div class="orgPrice">原价:{{item.goodsOrgPrice}}</div>
          </div>
        </div>
    </div>

    <van-overlay :show="show">
      <div class="wrapper" @click.stop>
        <div class="block_container">
          <div class="block_title">
            <div class="block_close" @click="show = false"></div>
          </div>
          <div class="block_body" v-if="step == 0">
            <div class="body_title">买酒领红包</div>
            <div class="body_coin">
              <div class="body_coin_img" @click="toClaimPack"></div>
            </div>
            <div class="body_message">扫拼码，领红包</div>
          </div>
          <div class="block_body1" v-if="step == 1">
            <div class="body_title1">买洒领红包，恭喜发财</div>
            <div class="body_number" @click="step = 2">{{redPackSum}}</div>
            <div class="body_deposit">红包已存入余额</div>
            <div class="body_message1">扫拼码，领红包</div>
          </div>
          <div class="block_body2" v-if="step == 2">
            <div class="body_title2">这个红包已领取哦～</div>
            <div class="body_dog">
              <div class="body_dog_img" @click="step = 0"></div>
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { listPack,claimPack } from "@/api/redpack";
export default {
  components: {
  },
  data() {
    return {
      show: true,
      step: 0,
      redPackSum:0,
      redPackList:[

      ]
    };
  },
  created() {
    this.getList();
  },
  methods: {
    toClaimPack(){
      let id = this.$route.params.redPackId;
      claimPack(id).then(response => {
        if (response && response.code == 200){
          if (response.data.claimStatus == "1"){
            this.step = 2;
          } else {
            this.redPackSum = response.data.goodsRelPrice;
            this.step = 1;
          }
        }
      });
    },
    toGoodsPage(index){
      var redPack = this.redPackList[index];
      this.$router.push({ name: 'goods', query: { id: redPack.goodsId } })
    },
    getList(){
      listPack({}).then(response => {
        if (response && response.code == 200){
          this.redPackList = response.rows;
        }
      });
    }
  }
};
</script>

<style lang="less">
  .bg-image(@picname) {
    background-image: url("../../../static/image/@{picname}.png");
    background-repeat: no-repeat;
    background-size:100% 100%;
    @media (-webkit-min-device-pixel-ratio: 2),(min-device-pixel-ratio: 2) {
      background-image: url("../../../static/image/@{picname}@2x.png");
      background-repeat: no-repeat;
      background-size:100% 100%;
    }
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .block_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .block_title{
      height: 32px;
      width: 297px;
      margin-bottom: 30px;
      .block_close{
        float: right;
        height: 32px;
        width: 32px;
        .bg-image('group3');
      }
    }
    .block_body2{
      width: 297px;
      height: 340px;
      .bg-image('invalide');
      .body_title2{
        margin-top: 52px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-weight: 500;
        text-align: left;
        color: #ffe49c;
        margin-bottom: 30px;
      }
      .body_dog{
        height: 180px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .body_dog_img{
          width: 161px;
          height: 180px;
          .bg-image('dog');
        }
      }
    }
    .block_body1{
      width: 297px;
      height: 340px;
      .bg-image('claim2');
      .body_title1{
        margin-top: 52px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-weight: 400;
        text-align: left;
        color: #242a31;
        line-height: 28px;
      }
      .body_number{
        margin-top: 43px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 48px;
        font-weight: 600;
        text-align: left;
        color: #e82828;
        line-height: 56px;
      }
      .body_deposit{
        margin-top: 43px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        font-weight: 400;
        text-align: left;
        color: #fde9b8;
        line-height: 18px;
      }
      .body_message1{
        margin-top: 29px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        text-align: left;
        color: #fde9b8;
        line-height: 22px;
      }
    }
    .block_body{
      width: 297px;
      height: 340px;
      .bg-image('group2');
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .body_title{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        height: 89px;
        font-weight: 500;
        text-align: left;
        color: #ffffff;
        line-height: 28px;
      }
      .body_coin{
        height: 106px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .body_coin_img{
          width: 106px;
          height: 106px;
          .bg-image('group4');
        }
      }
      .body_message{
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        text-align: left;
        color: #fde9b8;
        line-height: 22px;
      }
    }
  }
.redpack {
  background-color: #f1f4f9;
  padding: 17px 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .goodsItem{
    background-color: #ffffff;
    padding: 16px 10px 15px 10px;
    margin-bottom: 14px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    border-radius: 10px;
    img{
      width: 140px;
      height: 140px;
    }
    .textContainer{
      padding-left: 10px;
      padding-right: 10px;
      height: 140px;
      width: 195px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      .textTitle{
        font-size: 16px;
        font-weight: 500;
        text-align: left;
        color: #333333;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
      .textPrice{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .relPrice{
          font-size: 16px;
          font-weight: 700;
          color: #F14F35;
          line-height: 22px;
          margin-bottom: 6px;
        }
        .orgPrice{
          font-size: 16px;
          font-weight: 700;
          text-align: left;
          color: #a7a7a7;
          line-height: 22px;
        }
      }
    }
  }
}
</style>
